import clientService from '@/services/queries/clientQueries'
import ClientsProfileInfoBlock from './ClientsProfileInfoBlock/ClientsProfileInfoBlock.vue'

export default {
  components: {
    ClientsProfileInfoBlock
  },

  data() {
    return {
      client: null,
      notes: '',

      genders: this.$t('genders'),
      selectedGender: null,
      selectedFigureType: null,
      selectedClothingSizesForMetrics: {},
    }
  },

  created() {
    this.setClientData().then(() => {
      this.setSelectedGender()
      this.setSelectedClothingSizesForMetrics()

      this.selectedFigureType = this.getFigureTypeById(this.client.figure_type_id)
    })
  },

  methods: {
    setClientData() {
      return clientService.find(this.$route.params.id).then(data => {
        this.client = data.client
        this.notes = data.notes
      })
    },

    setSelectedGender() {
      if (!this.client.gender) {
        return
      }

      this.selectedGender = this.genders.find(gender => {
        return gender.value.toLowerCase() === this.client.gender.toLowerCase()
      })
    },

    setSelectedClothingSizesForMetrics() {
      let selectedClothingSizes = this.client.clothing_sizes

      this.filteredClothingSizeMetrics.forEach(metric => {
        this.selectedClothingSizesForMetrics[metric.clothing_size_metric_id] = selectedClothingSizes.find(size => {
          return size.clothing_size_metric_id === metric.clothing_size_metric_id
        })
      })
    },

    editClient() {
      this.$router.push({
        name: 'agent.clients.edit',
        params: {
          id: this.client.user_id
        }
      })
    }
  },

  computed: {
    ...mapGetters('handbooks', [
      'getFigureTypeById'
    ]),

    filteredFigureTypes() {
      if (!this.selectedGender) {
        return []
      }

      return this.$store.state.handbooks.figureTypes.filter(figureType => {
        return figureType.gender.toLowerCase() === this.selectedGender.value.toLowerCase()
      })
    },

    filteredClothingSizeMetrics() {
      if (!this.selectedGender) {
        return []
      }

      return this.$store.state.handbooks.clothingSizeMetrics.filter(clothingSizeMetric => {
        return clothingSizeMetric.gender.toLowerCase() === this.selectedGender.value.toLowerCase()
      })
    }
  },
}
